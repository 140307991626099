import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {createGlobalStyle} from 'styled-components'
import {cssreset} from "./cssreset";

const GlobalStyle = createGlobalStyle`
  ${cssreset}
  :root {
    --text-clr: black;
    --ff-primary: 'Roboto', sans-serif;
    --ff-body: var(--ff-primary);
    --ff-heading: var(--ff-primary);
    --fw-regular: 400;
    --fw-semi-bold: 500; // TODO maybe throw away
    --fw-bold: 700;

    --fs-heading: 3.75rem;
    --fs-secondary-heading: 2rem;
    --fs-body: 1rem;
    --fs-caption: 1rem
  }

  body {
    font-size: var(--fs-body);
    font-family: var(--ff-body);
  }

  @media (min-width: 50em) {
    :root {
      //--fs-heading: 5rem
    }
  }
`
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <GlobalStyle/>
        <App/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
