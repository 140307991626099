import styled from 'styled-components'

export const Container = styled.div`
  --max-width: 1100px;
  --padding: 1rem;

  width: min(var(--max-width), 100% - (var(--padding) * 2));
  margin-inline: auto;
`

export const Toolbar = styled.div`
  position: sticky;
  top: 0;
  background: white;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
`

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
`

export const Logo = styled.img`
  width: 48px;
`

export const LogoText = styled.h6`
  font-size: 1.25rem;
  margin-left: 8px;
  font-weight: 500;
`

export const HeroSectionContainer = styled.div`
  display: grid;
  gap: 6rem;
  align-items: center;
  justify-items: center;

  @media (min-width: 50em) {
    grid-auto-flow: column;
    //grid-auto-columns: 1fr;
  }
`

export const HeroSectionTextContainer = styled.div`
`

export const HeroHeadline = styled.h1`
  font-family: var(--ff-heading);
  font-size: var(--fs-heading);
  color: var(--text-clr);
  line-height: 120%;
  font-weight: var(--fw-bold);
`

export const HeroSubHeader = styled.h2`
  font-size: 1.5rem;
  line-height: 1.334;
  font-weight: normal;
`

export const HeroImage = styled.img`
  max-width: 350px;
  max-height: 721px;
`

export const GooglePlayBadgeImageContainer = styled.a`
  display: flex;
  align-content: center;
  align-self: center;
  alignment: center;
  justify-content: center;
  @media (min-width: 50em) {
    display: inline;
  }
`
export const GooglePlayBadgeImageAnchor = styled.a`
  display: inline-block;
  justify-content: center;
  width: max-content;
  alignment: center;
  align-self: center;
  align-content: center;
`

export const GooglePlayBadgeImage = styled.img`
  alignment: center;
  margin-top: 2rem;
  width: 300px;
  height: 116px;
  @media (min-width: 50em) {
    margin-left: -1rem;
  }
`

export const FeatureContainer = styled.div`
  margin-top: 3rem;
  display: grid;
  gap: 2rem;
  align-items: center;
  justify-items: center;
  margin-left: auto;
  margin-right: auto;
  transform: ${props => props.isVisible ? "none" : "translateY(10%)"};
  opacity: ${props => props.isVisible ? 1 : 0};
  transition: all 500ms;

  @media (min-width: 50em) {
    grid-auto-flow: column;
  }
`

export const FeatureImageWrapper = styled.div`
  text-align: center;
  order: 1;
  @media (min-width: 50em) {
    order: ${props => props.reverse ? 0 : 1};
  }
`

export const FeatureImage = styled.img`
  width: 300px;
  alignment: center;
`

export const FeatureTextContainer = styled.div`
  max-width: 350px;
  order: 0;
  @media (min-width: 50em) {
    order: ${props => props.reverse ? 1 : 0};
  }
`

export const FeatureCaption = styled.caption` // TODO what is caption?
  font-size: var(--fs-caption);
  letter-spacing: 0.1em;
  line-height: 120%;
  text-transform: uppercase;
`

export const FeatureHeadline = styled.h3`
  font-size: var(--fs-secondary-heading);
  line-height: 120%;
  font-weight: var(--fw-semi-bold);
`
export const FeatureDescription = styled.body`
`

export const CenteredContainer = styled.div`
  margin-top: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 5rem;
`