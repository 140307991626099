import {useEffect, useRef, useState} from "react";

export const useElementWasShownOnScreen = () => {
    let options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.3
    }
    const containerRef = useRef(null)
    const [isShown, setIsShown] = useState(false)
    const callbackFunction = (entries) => {
        const [entry] = entries
        if (entry.isIntersecting) {
            setIsShown(true)
        }
    }
    useEffect(() => {
        const observer = new IntersectionObserver(callbackFunction, options)
        if (containerRef.current) observer.observe(containerRef.current)
        return () => {
            if (containerRef.current) observer.unobserve(containerRef.current)
        }
    }, [containerRef, options])
    return [containerRef, isShown]
}