import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
// Import the functions you need from the SDKs you need
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "./firebase";
import React from "react";
import {Container, ThemeProvider} from "@mui/material";
import {createTheme, responsiveFontSizes} from '@mui/material/styles';
import {install} from 'ga-gtag';
import {LandingTwo} from "./landing/Landing_two";
import PrivacyPolicy from "./privacy/PrivacyPolicy";
import TermsOfService from "./privacy/TermsOfService";
import DeleteAccount from "./privacy/DeleteAccount";

function App() {
    install('AW-10885499850');
    let theme = createTheme();
    theme = responsiveFontSizes(theme);
    const [user, loading, error] = useAuthState(auth);
    return (<div>
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                {/*<CheckstoryAppBar user={user}/>*/}
                <Routes>
                    <Route path="/" element={<LandingTwo/>}/>
                    <Route path="/privacy" element={<PrivacyPolicy/>}/>
                    <Route path="/terms-of-service" element={<TermsOfService/>}/>
                    <Route path="/delete-account" element={<DeleteAccount/>}/>
                    {/*<Route path="/app/login" element={<Login/>}/>*/}
                    {/*<Route exact path="/app/register" element={<Register/>}/>*/}
                    {/*<Route exact path="/app/reset" element={<Reset/>}/>*/}
                    {/*<Route path="/app" element={<ChecklistCatalog/>}/>*/}
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    </div>);
}

function RoutedApp(
    {
        user
    }
) {
    return <BrowserRouter>
        <Container>
            <Routes>
                {/*<Route path="/app/checklist/new/:templateId" element={<FillChecklistView/>}/>*/}
                {/*<Route path="/app/template/:templateId" element={<EditTemplateView/>}/>*/}
                {/*<Route path="/app/template/new" element={<EditTemplateView/>}/>*/}
                {/*<Route path="/app/profile" element={<LoginStatus/>}/>*/}
                {/*<Route path="/open" element={<OpenStartupDashboard/>}/>*/}
            </Routes>
        </Container>
    </BrowserRouter>
}

export default App;
