export function getUtmParams() {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    let medium = ""
    if (params.has('utm_medium')) {
        medium = '&utm_medium=' + params.get('utm_medium')
    }
    let campaign = ""
    if (params.has('utm_campaign')) {
        campaign = '&utm_campaign=' + params.get('utm_campaign')
    }
    return medium + campaign;
}