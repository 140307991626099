import React, {useState} from "react";

export default function DeleteAccount() {
    return <div>
        <div className="container">
            <div className="main-container">
                <h2 style={{marginTop: 0}}>Checkstory for Checklists: Delete Account</h2>
                <h3 style={{marginTop: 8, marginBottom: 8}}>Request account deletion by providing an e-mail you used. We
                    will reach out to confirm the deletion request.</h3>
                <h3 style={{marginTop: 8, marginBottom: 8}}>We will delete all the data you provided - your email,
                    templates and checklists - within 90 days of request confirmation.</h3>
                <DeleteAccountForm/>
            </div>
        </div>
    </div>
}

const DeleteAccountForm = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://api.checkstory.tech/v1/delete-account-form', {
                // const response = await fetch('http://localhost:8080/delete-account-form', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({email}),
            });

            if (response.ok) {
                setMessage('Your request has been submitted successfully.');
            } else {
                setMessage('There was an error submitting your request.');
            }
        } catch (error) {
            setMessage('There was an error submitting your request.');
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <label>
                    Email:
                    <input
                        style={{marginLeft: 8}}
                        type="email"
                        value={email}
                        maxLength={254}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </label>
                <button
                    style={{marginLeft: 8}}
                    type="submit">Submit
                </button>
            </form>
            {message && <p style={{marginTop: 4}}>{message}</p>}
        </div>
    );
};

