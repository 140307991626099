import React from "react";

export default function PrivacyPolicy() {
    return <div>
        <div className="container">
            <div className="main-container">
                <h2 style={{marginTop: 0}}>Checkstory Privacy Policy</h2>
                <hr style={{marginBottom: 1 + 'em'}}/>
                <div id="content">
                    <p>The Checkstory app is developed and managed by “SZYMON CHABER”, a sole proprietorship company
                        registered in Poland, NIP (EU Tax ID): PL 7831804012.</p>
                    <p>This page informs visitors about our policies regarding the collection, use, and disclosure of
                        Personal Information if anyone decides to use our Service.</p>
                    <p>If you choose to use our Service, then you agree to the collection and use of information in
                        relation
                        to this policy. The Personal Information we collect is used to provide and improve the Service.
                        We
                        will not use or share your information with anyone except as described in this Privacy
                        Policy.</p>
                    <p>The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions,
                        which
                        are accessible at Checkstory unless otherwise defined in this Privacy Policy.</p>
                    <h2>Information Collection and Use</h2>
                    <p>For a better experience while using our Service, we may require you to provide us with certain
                        personally identifiable information, including but not limited to your email address. The
                        personal
                        data we collect is stored and processed for as long as required by the purpose they have been
                        collected for. For instance, if you provide us with your email address to use our services, we
                        will
                        retain your email address for as long as your account is active or as needed to provide you with
                        our
                        services. Beyond this period, your data will be securely deleted.</p>
                    <p>The app uses third-party services that may collect information to identify you.</p>
                    <p>Google Play Services<br/>AdMob<br/>Google Analytics for Firebase<br/>Firebase Crashlytics<br/>Facebook
                    </p>
                    <h2>Legal Basis for Processing</h2>
                    <p>We process Personal Data for the purposes set out in this Privacy Policy, as described above. Our
                        legal basis for processing personal data includes processing that is necessary for the
                        performance
                        of the contract with you (for example, to provide you with the services you request and to
                        identify
                        and authenticate you so you may use the Site); necessary to comply with legal requirements (for
                        example, to comply with applicable accounting rules and to make mandatory disclosures to law
                        enforcement); necessary for our legitimate interests (for example, to manage our relationship
                        with
                        you, to ensure the security of our services, to communicate with you about our products and
                        services); and based on consent by our customers (for example, to place certain cookies and to
                        share
                        your information with third parties for advertising purposes).</p>
                    <p>In some instances, you may be required to provide us with Personal Data for processing, as
                        described
                        above, in order for us to be able to provide you with all of our services and for you to use all
                        the
                        features of our Application.</p>
                    <h2>Log Data</h2>
                    <p>We want to inform you that whenever you use our Service, in a case of an error in the app, we
                        collect
                        data and information (through third-party products) on your phone called Log Data. This Log Data
                        may
                        include information such as your device's Internet Protocol ("IP") address, device name,
                        operating
                        system version, the app's configuration when utilizing our Service, the time and date of your
                        use of
                        the Service, and other statistics.</p>
                    <h2>Cookies</h2>
                    <p>Cookies are files with a small amount of data commonly used as anonymous unique identifiers.
                        These
                        are sent to your browser from the websites you visit and stored on your device's internal
                        memory.</p>
                    <p>This Service does not use these "cookies" explicitly. However, the app may use third-party code
                        and
                        libraries that use "cookies" to collect information and improve their services. You can either
                        accept or refuse these cookies and know when a cookie is being sent to your device. If you
                        choose to
                        refuse our cookies, you may not be able to use some portions of this Service.</p>
                    <h2>Service Providers</h2>
                    <p>We may employ third-party companies and individuals due to the following reasons:</p>
                    <p>To facilitate our Service;<br/>To provide the Service on our behalf;<br/>To perform
                        Service-related
                        services; or<br/>To assist us in analyzing how our Service is used.</p>
                    <p>We want to inform users of this Service that these third parties have access to their Personal
                        Information. The reason is to perform the tasks assigned to them on our behalf. However, they
                        are
                        obligated not to disclose or use the information for any other purpose.</p>
                    <h2>Data Transfers</h2>
                    <p>We may transfer the information we collect about you, including personal data, to affiliated
                        entities, or to other third parties across borders and from your country or jurisdiction to
                        other
                        countries or jurisdictions around the world. Please note that these countries and jurisdictions
                        may
                        not have the same data protection laws as your jurisdiction, and you consent to the transfer of
                        information to such countries and the use and disclosure of information about you, including
                        personal data, as described in this Privacy Policy.</p>
                    <h2>Security</h2>
                    <p>We value your trust in providing us with your Personal Information. Thus we are striving to use
                        commercially acceptable means of protecting it. But remember that no method of transmission over
                        the
                        internet or method of electronic storage is 100% secure and reliable, and we cannot guarantee
                        its
                        absolute security.</p>
                    <h2>Links to Other Sites</h2>
                    <p>This Service may contain links to other sites. You will be directed to that site if you click on
                        a
                        third-party link. Note that we do not operate these external sites. Therefore, we strongly
                        advise
                        you to review the Privacy Policy of these websites. We have no control over and assume no
                        responsibility for any third-party sites or services' content, privacy policies, or
                        practices.</p>
                    <h2>Children's Privacy</h2>
                    <p>These Services do not address anyone under the age of 13. We do not knowingly collect personally
                        identifiable information from children under 13. If we discover that a child under 13 has
                        provided
                        us with personal information, we immediately delete this from our servers. If you are a parent
                        or
                        guardian and know that your child has provided us with personal information, please contact us
                        so we
                        can take the necessary actions.</p>
                    <h2>User's Rights</h2>
                    <p>If you are a resident of the European Economic Area (EEA), you have certain data protection
                        rights.
                        These include the right to: (a) access and receive a copy of your personal data; (b) rectify
                        inaccurate personal data; (c) request erasure of your personal data; (d) object to the
                        processing of
                        your personal data; (e) request restriction of processing of your personal data; (f) data
                        portability. If you wish to exercise any of these rights, please contact us using the contact
                        information provided in this Privacy Policy.</p>
                    <h2>Complaints</h2>
                    <p>If you are a resident of the EEA and have a concern about our processing of personal data that we
                        are
                        not able to resolve, you have the right to lodge a complaint with the data protection authority
                        where you reside. For contact details of your local Data Protection Authority, please see:
                        http://ec.europa.eu/justice/data-protection/article-29/structure/data-protection-authorities/index_en.htm.</p>
                    <h2>Changes to This Privacy Policy</h2>
                    <p>We may update our Privacy Policy from time to time. Thus, you are advised to review this page
                        periodically for any changes. We will notify you of any changes by posting the new Privacy
                        Policy on
                        this page.</p>
                    <p>This policy is effective as of 2023-07-25</p>
                    <h2>Contact Us</h2>
                    <p>If you have any questions or suggestions about our Privacy Policy, or if you wish to exercise any
                        of
                        your data protection rights, please contact us at szymon@szymonchaber.dev.</p>
                </div>
            </div>
        </div>
    </div>
}
