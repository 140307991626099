import logo from "../logo.svg"
import {
    CenteredContainer,
    Container,
    FeatureCaption,
    FeatureContainer,
    FeatureDescription,
    FeatureHeadline,
    FeatureImage,
    FeatureImageWrapper,
    FeatureTextContainer,
    GooglePlayBadgeImage,
    GooglePlayBadgeImageAnchor,
    GooglePlayBadgeImageContainer,
    HeroHeadline,
    HeroImage,
    HeroSectionContainer,
    HeroSectionTextContainer,
    HeroSubHeader,
    Logo,
    LogoContainer,
    LogoText,
    Toolbar
} from './styled/Components'
import heroScreenshot from "./checklist_catalog.webp"
import {logEvent} from "firebase/analytics";
import {analytics} from "../firebase";
import gtag from "ga-gtag";
import {getUtmParams} from "../analytics/GetUtmParams";
import editTemplateScreenshot from "./edit_template.webp"
import fillChecklistScreenshot from "./fill_checklist_empty.webp"
import checklistHistoryScreenshot from "./checklist_history.webp"
import fillNotesScreenshot from "./fill_checklist_filled.webp"
import {useElementWasShownOnScreen} from "./useElementWasShownOnScreen";

function HeroSection() {
    return <HeroSectionContainer>
        <HeroSectionTextContainer>
            <HeroHeadline>Checklists like they're<br/>meant to be</HeroHeadline>
            <HeroSubHeader>Create checklists that fit your needs and use them again and again</HeroSubHeader>
            <GooglePlayBadge location="top"/>
        </HeroSectionTextContainer>
        <HeroImage src={heroScreenshot}/>
    </HeroSectionContainer>;
}

function GooglePlayBadge({location}) {
    let handleClick = (e) => {
        e.preventDefault();
        logEvent(analytics, "get_on_google_play_button_clicked", {
            location: location,
        })
        gtag('event', 'conversion', {'send_to': 'AW-10885499850/LDjtCPfgjNgDEMqXzsYo'});
        let utmParams = getUtmParams();
        console.log(utmParams)
        let url = 'https://play.google.com/store/apps/details?id=dev.szymonchaber.checkstory&utm_source=checkstory.tech' + utmParams;
        console.log(url)
        window.open(url, "_self")
    };
    return <GooglePlayBadgeImageContainer>
        <GooglePlayBadgeImageAnchor href="#" onClick={handleClick}>
            <GooglePlayBadgeImage
                width="300"
                height="116"
                alt='Get it on Google Play'
                src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
        </GooglePlayBadgeImageAnchor>
    </GooglePlayBadgeImageContainer>;
}

export function Features() {
    return <>
        <AnimatedFeatureContainer>
            <FeatureImageWrapper reverse={true}>
                <FeatureImage src={editTemplateScreenshot}/>
            </FeatureImageWrapper>
            <FeatureTextContainer reverse={true}>
                <FeatureCaption>Prepare</FeatureCaption>
                <FeatureHeadline>Create endless checklist templates.</FeatureHeadline>
                <FeatureDescription>Get organized with sections.
                    Let us remind you when it's time for a checklist - daily, weekly, yearly - you get the
                    idea.</FeatureDescription>
            </FeatureTextContainer>
        </AnimatedFeatureContainer>
        <AnimatedFeatureContainer>
            <FeatureTextContainer>
                <FeatureCaption>Perform</FeatureCaption>
                <FeatureHeadline>
                    Fill out your checklists.
                </FeatureHeadline>
                <FeatureDescription>
                    Creating a new checklist is one click away - clear and ready for work.
                    No longer will you need to un-check anything to start a new one.
                    You can run multiple lists simultaneously, adding to them as you go.
                </FeatureDescription>
            </FeatureTextContainer>
            <FeatureImageWrapper>
                <FeatureImage src={fillChecklistScreenshot}/>
            </FeatureImageWrapper>
        </AnimatedFeatureContainer>
        <AnimatedFeatureContainer>
            <FeatureImageWrapper reverse={true}>
                <FeatureImage src={fillNotesScreenshot}/>
            </FeatureImageWrapper>
            <FeatureTextContainer reverse={true}>
                <FeatureCaption>Reflect</FeatureCaption>
                <FeatureHeadline>Add notes after a session.</FeatureHeadline>
                <FeatureDescription>
                    General notes, specifics for later, additional tasks done - everything goes!
                </FeatureDescription>
            </FeatureTextContainer>
        </AnimatedFeatureContainer>
        <AnimatedFeatureContainer>
            <FeatureTextContainer>
                <FeatureCaption>Inspect</FeatureCaption>
                <FeatureHeadline>Check your history.</FeatureHeadline>
                <FeatureDescription>
                    Your checklists grow with you - without rewriting history.
                    Past entries are there for review - precisely as when you saved them.
                    Learn how you performed, what notes you had & how many items you filled.
                </FeatureDescription>
            </FeatureTextContainer>
            <FeatureImageWrapper>
                <FeatureImage src={checklistHistoryScreenshot}/>
            </FeatureImageWrapper>
        </AnimatedFeatureContainer>
    </>;
}

export function AnimatedFeatureContainer({children}) {
    const [containerRef, didShow] = useElementWasShownOnScreen()
    return <FeatureContainer isVisible={didShow} ref={containerRef}>{children}</FeatureContainer>
}

export function LandingTwo() {
    return <Container>
        <Toolbar>
            <LogoContainer>
                <Logo src={logo}/>
                <LogoText>Checkstory</LogoText>
            </LogoContainer>
        </Toolbar>
        <HeroSection/>
        <Features/>
        <CenteredContainer>
            <GooglePlayBadge location="bottom"/>
        </CenteredContainer>
    </Container>;
}