import React from "react";

export default function TermsOfService() {
    return <div>
        <div className="container">
            <div className="main-container">
                <h2 style={{marginTop: 0}}>Checkstory Terms of Service</h2>
                <hr style={{marginBottom: 1 + 'em'}}/>
                <div id="content">
                    <h2>Acceptance of Terms</h2>
                    <p>By downloading or using the app, these terms will automatically apply to you – you should make
                        sure
                        therefore that you read them carefully before using the app. You’re not allowed to copy or
                        modify
                        the app, any part of the app, or our trademarks in any way.</p>
                    <h2>User Conduct</h2>
                    <p>When using our app, provided by SZYMON CHABER, you agree to refrain from conduct
                        that
                        is harmful, harassing, illegal, or promotes hate speech. Violation of this code of conduct may
                        result in warnings, suspension, or termination of your access to the app.</p>
                    <h2>Intellectual Property</h2>
                    <p>You’re not allowed to attempt to extract the source code of the app, and you also shouldn’t try
                        to
                        translate the app into other languages or make derivative versions. The app itself, and all the
                        trademarks, copyright, database rights, and other intellectual property rights related to it,
                        still
                        belong to SZYMON CHABER.</p>
                    <h2>Changes and Payment</h2>
                    <p>SZYMON CHABER is committed to ensuring that the app is as useful and efficient as possible. For
                        that
                        reason, we reserve the right to make changes to the app or to charge for its services, at any
                        time
                        and for any reason. We will never charge you for the app or its services without making it very
                        clear to you exactly what you’re paying for.</p>
                    <h2>Data Protection</h2>
                    <p>The Checkstory app, provided by SZYMON CHABER, stores and processes personal data that you have
                        provided to us, to provide our Service. For a more detailed overview of how we handle data,
                        including collection, use, and disclosure practices, please refer to our full Privacy
                        Policy.</p>
                    <h2>Security</h2>
                    <p>It’s your responsibility to keep your phone and access to the app secure. We therefore recommend
                        that
                        you do not jailbreak or root your phone, which is the process of removing software restrictions
                        and
                        limitations imposed by the official operating system of your device. It could make your phone
                        vulnerable to malware/viruses/malicious programs, compromise your phone’s security features and
                        it
                        could mean that the Checkstory app won’t work properly or at all.</p>
                    <h2>Third Party Services</h2>
                    <p>The app does use third-party services that declare their Terms and Conditions. By accepting our
                        Terms
                        and Conditions, you also acknowledge and accept the terms of the third-party services.</p>
                    <p>Links to Terms and Conditions of third-party service providers used by the app:</p>
                    <ul>
                        <li><a href="https://policies.google.com/terms">Google Play Services</a></li>
                        <li><a href="https://developers.google.com/admob/terms">AdMob</a></li>
                        <li><a href="https://firebase.google.com/terms/analytics">Google Analytics for Firebase</a></li>
                        <li><a href="https://firebase.google.com/terms/crashlytics">Firebase Crashlytics</a></li>
                        <li><a href="https://www.facebook.com/legal/terms/plain_text_terms">Facebook</a></li>
                    </ul>
                    <h2>Limitations of Liability</h2>
                    <p>You should be aware that there are certain things that SZYMON CHABER will not take responsibility
                        for. Certain functions of the app will require the app to have an active internet
                        connection.</p>
                    <h2>Jurisdiction and Governing Law</h2>
                    <p>Any disputes arising from or related to the use of this app will
                        be
                        governed by and construed in accordance with the laws of Poland, and you consent to the
                        exclusive
                        jurisdiction and venue of courts in Poland.</p>
                    <h2>Dispute Resolution</h2>
                    <p>In the event of any disputes related to the use of the app, users and SZYMON
                        CHABER agree to first attempt to resolve the dispute through mutual consultation. If the dispute
                        is
                        not resolved through consultation within 30 days, either party may seek resolution through
                        arbitration or the courts in the above-mentioned jurisdiction.</p>
                    <h2>Mobile Network and Costs</h2>
                    <p>If you’re using the app outside of an area with Wi-Fi, you should remember that the terms of the
                        agreement with your mobile network provider will still apply.</p>
                    <h2>Device Maintenance</h2>
                    <p>SZYMON CHABER cannot always take responsibility for the way you use the app i.e. you need to make
                        sure that your device stays charged.</p>
                    <h2>App Information and Updates</h2>
                    <p>With respect to SZYMON CHABER’s responsibility for your use of the app, when you’re using the
                        app,
                        it’s important to bear in mind that although we endeavor to ensure that it is updated and
                        correct at
                        all times, we do rely on third parties to provide information to us so that we can make it
                        available
                        to you. Szymon Chaber accepts no liability for any loss, direct or indirect, you experience as a
                        result of relying wholly on this functionality of the app.</p>
                    <h2>App Updates and Termination</h2>
                    <p>At some point, we may wish to update the app. You are obliged to accept updates to the
                        application
                        when they are offered. We may also decide to discontinue providing the app, and may terminate
                        use of
                        it at any time with reasonable notice to users, except in emergency situations</p>
                    <p>Refund Policy:</p>
                    <p>Users can request a refund within 7 days of Checkstory PRO purchase.</p>
                    <h2>Changes to This Terms and Conditions</h2>
                    <p>SZYMON CHABER may update our Terms and Conditions from time
                        to
                        time. Thus, you are advised to review this page periodically for any changes.</p>
                    <p>These terms and conditions are effective as of 2023-07-27.</p>
                    <h2>Contact Us</h2>
                    <p>If you have any questions or suggestions about our Terms and Conditions, do not hesitate to
                        contact us at szymon@szymonchaber.dev.</p>
                </div>
            </div>
        </div>
    </div>
}
